import React, { Component } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import './product.css';
import 'antd/dist/antd.css';
//import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
//import AccountHeader from './AccountHeader';
import AccountHeader from '../Account/AccountHeader';
import {
  Card,
  Descriptions,
  Row,
  Col,
  Modal,
  Button,
} from 'antd';
import {
  
  IMAGE_URL,
  COMMIT_FOR_ASSET,
  GET_PRODUCT_DETAIL,
  get_data_token,
} from '../../config/network';
//import { Link, } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage, ModalBox } from '../MessageBox';

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: {},
      btnStatus: true,
      Confirmvisible:false,
      lentStatus:false,
    };
    this.handleCommit = this.handleCommit.bind(this);        
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

  }


  componentWillMount() {
    //console.log(this.props);
    let id = this.props.match.params.uniquekey;
    console.log(id);
    get_data_token(GET_PRODUCT_DETAIL, { productId: id })

      .then(data => {
        this.setState({ products: data });
        this.state.products.available===0?
        this.setState({
          btnStatus: true,
          lentStatus:false,
         })
         :
         this.setState({
          btnStatus: false,
          lentStatus:true,
         })
         //
      })
      .catch(err => {
        showErrorMessage.call(this, 'Get product info,' + err);
      })

  };
  
handleOk = () => {
  get_data_token(COMMIT_FOR_ASSET, { productId: this.state.products.product_id })
  .then(data => {
    this.setState({
                    btnStatus: false,
                    Confirmvisible:false,
                    lentStatus:true,
                 });
  })
  .catch(err => showErrorMessage.call(this, 'error:' + err))
    // this.setState({
    //     btnStatus: false,
    //     Confirmvisible:false,
    // });

};

handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
    //  btnStatus: false,
      Confirmvisible:false,
  });
};

handleCommit(event) {

    console.log('Commit');
/*     get_data_token(COMMIT_FOR_ASSET, { productId: this.state.products.product_id })
    .then(data => {
      this.setState({Confirmvisible:true});
    })
    .catch(err => showErrorMessage.call(this, 'error:' + err)) */
    this.setState({Confirmvisible:true});
  }
  
  render() {  

    let products = this.state.products;
    var text = "red";
    var style = {
        color:text
    }
    return (
<div>
        <div className="main">
          <AccountHeader page={"Product"} title="Product" />
      <div className="main-body">

    


        <Row gutter={16}>

          <Col span={2}>
            
          </Col>
          <Col span={8}>
            <Card style={{ width: 600 }} title={products.product_name} size="default" bordered={true}>
              <img style={{ width: 500 }} alt={products.product_name} src={IMAGE_URL+ products.product_image} />

            </Card>
          </Col>
          <Col span={10}>
            <Card title="Product Description" bordered={false}>
              <Descriptions
                title=""
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label="Product Name" span={3}>{products.product_name}</Descriptions.Item>
                <Descriptions.Item label="Manufacturer">{products.manufacturer}</Descriptions.Item>
                <Descriptions.Item label="UOM">{products.uom}</Descriptions.Item>
                <Descriptions.Item label="Part Number">{products.part_num}</Descriptions.Item>
                <Descriptions.Item label="Description" span={4}>{products.product_desc}</Descriptions.Item>

                <Descriptions.Item label="Instruction" span={8}>
                  {products.instruction}
                </Descriptions.Item>

              </Descriptions>
              <p /> 
              <p />
                   <p align='right' style={style}>
                     {
                       this.state.lentStatus===false ?<p></p>:
                         <p>
                         {products.delivered===1?<p>This product has been rented.</p>:<p>This product is not available.</p>}
                         </p>
                      }               

                   </p>
                {this.state.btnStatus===false ?<p align='right'><Button type='primary' disabled size='large' >Reserve for pickup</Button></p>
                    : <p align='right'><Button type='primary' size='large' onClick={this.handleCommit}>Reserve for pickup</Button></p>}

                  <Modal
                           title="Do you reserve this for pickup?"
                           visible={this.state.Confirmvisible} 
                           onOk={this.handleOk}
                           onCancel={this.handleCancel}
                 >
                 </Modal>
            </Card>
          </Col>
          <Col span={4}>
          
          </Col>
        </Row>
       
  


      </div >
      </div >
      </div >
    );

  }





}



export default ProductDetails;