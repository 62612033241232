import React, { Component } from 'react';
import './Account.css'
//import { Col, Button, Form, FormGroup, Label, Input, } from 'reactstrap';
import './Profile.css'
import 'antd/dist/antd.css';
import AccountHeader from './AccountHeader';
import {
    Form,
    Input,
    Tooltip,
    Icon,
    Button,
    message,

} from 'antd';
import Select from 'react-select';
import {
    GET_MEMBER_INFO_URL,
    MODIFY_PROFILE,
    get_data_token,
    post_data_token,
    GET_STATELIST,
} from '../../config/network';
import { Link, } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage, } from '../MessageBox';
//import { FormattedMessage } from 'react-intl';
import './DashboardCard.css'



class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberId: '',
            phone: '',
            email: '',
            first_name: '',
            last_name: '',
            company: '',
            city: '',
            state: '',
            zipcode: '',
            addressline1: '',
            addressline2: '',
            birth: '',
            nickname: '',
            statenames: [],
            selectedState: '',
            selectedStateCode:'',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }
    componentDidMount() {
        get_data_token(GET_MEMBER_INFO_URL, {})
            .then(data => {
                this.setState({
                    memberId: data.member.memberId,
                    phone: data.member.phone,
                    email: data.member.email,
                    first_name: data.profile.firstName,
                    last_name: data.profile.lastName,
                    company: data.profile.company,
                    city: data.profile.city,
                    state: data.profile.state,
                    selectedState: data.profile.state,
                    birth: data.profile.birth,
                    sex: data.profile.sex,
                    nickname: data.profile.nickName,
                    zipcode: data.profile.zipcode,
                    addressline1: data.profile.addressline1,
                    addressline2: data.profile.addressline2,
                })
            })
            .catch(err => {
                showErrorMessage.call(this, 'Get member info error,' + err);
            })

        get_data_token(GET_STATELIST, {})
            .then(data => {
                this.setState({ statenames: data.stateList });
                let statesFromApi = data.stateList.map(statename => { return { value: statename.stateCode, key: statename.state } })
                // console.log(states);
                this.setState({ statenames: statesFromApi });
            })
            .catch(err => {
                showErrorMessage.call(this, 'Get states info,' + err);
            })

    }

    handleSubmit(event) {

        event.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                //   console.log('Received values of form: ', values);
                console.log(this.state.selectedState);
                post_data_token(MODIFY_PROFILE, {
                    firstName: values.first_name,
                    lastName: values.last_name,
                    nickName: values.nickname,
                    email: values.email,
                    phone: values.phone,
                    zipcode: values.zipcode,
                    //      _company:this.state.company	,	
                    city: values.city,
                    state: this.state.selectedStateCode,
                    addressline1: values.addressline1,
                    addressline2: values.addressline2
                })
                    .then(data => message.success("Update Success！"))
                    //  .then(data => showSuccessMessage.call(this, <FormattedMessage id="page.profile.update.success" defaultMessage="Update success!" />))
                    .catch(err => showErrorMessage.call(this, 'Update error:' + err))
            }
        });
    }
    handleChange(event) {

        this.setState({ [event.target.id]: event.target.value });

    }
    handleStateChange(event) {
        let Vstate = this.state.statenames;
        let selectedstatecode = Vstate.filter(stateitem => (stateitem.key === event.target.value))
        console.log(selectedstatecode[0].value)
        this.setState({selectedState: event.target.value});
        this.setState({ selectedStateCode: selectedstatecode[0].value });

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let states = this.state.statenames;
        let optionItems = states.map((item) =>
            <option key={item.value} value={item.key}>{item.key}</option>
        );
        const formItemLayout = {
            labelCol: {
                xs: { span: 16 },
                sm: { span: 8},
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 16,
                    offset: 8,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        return (
            <div>
                <div className="main">
                    <AccountHeader page={"Profile"} title="Profile" />
                    <div className="main-body">
                        <div class="dcard">

                            <div Class="dcard-titile">
                                Sign In Info
                             </div>
                            <div className="dcard-body">
                                <p>
                                    Login Email:        {this.state.email}
                                </p>
                                <p><Link to="/account/ChangePassword" Class="pull-right title-link"><Button type="primary">
                                    Change Password
                                         </Button>
                                </Link>
                                </p>
                            </div>


                        </div>
                        <div class="dcard">
                            <div Class="dcard-titile">
                                <h4 Class="left">Personal Information</h4>
                            </div>
                            <div className="dcard-body" align='left'>

                                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                    <Form.Item label="email">
                                        {getFieldDecorator('email', {
                                            initialValue: this.state.email,
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please input your E-mail!',
                                                },
                                            ],
                                        })(< Input id="email" onChange={this.handleChange} />)}
                                    </Form.Item>
                                    <Form.Item label="phone">
                                        {getFieldDecorator('phone', {
                                            initialValue: this.state.phone,
                                            rules: [{ required: true, message: 'Please input your phone!', whitespace: true }],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label={<span> Nickname&nbsp;<Tooltip title="What do you want others to call you?"> <Icon type="question-circle-o" /> </Tooltip> </span>}>
                                        {getFieldDecorator('nickname', {
                                            initialValue: this.state.nickname,
                                            rules: [{ required: true, message: 'Please input your nickname!', whitespace: true }],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label="First Name">
                                        {getFieldDecorator('first_name', {
                                            initialValue: this.state.first_name,
                                            rules: [{ required: true, message: 'Please input your firstname!', whitespace: true }],
                                        })(<Input />)}

                                    </Form.Item>
                                    <Form.Item label="Last Name">
                                        {getFieldDecorator('last_name', {
                                            initialValue: this.state.last_name,
                                            rules: [{ required: true, message: 'Please input your lastname!', whitespace: true }],
                                        })(<Input />)}
                                    </Form.Item>
                                   
                                    <Form.Item label="addressline1">
                                        {getFieldDecorator('addressline1', {
                                            initialValue: this.state.addressline1,
                                            rules: [{ required: true, message: 'Please input your address!', whitespace: true }],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label="addressline2">
                                        {getFieldDecorator('addressline2', {
                                            initialValue: this.state.addressline2,
                                            rules: [{ required: false, message: '', whitespace: true }],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label="city">
                                        {getFieldDecorator('city', {
                                            initialValue: this.state.city,
                                            rules: [{ required: true, message: 'Please input your city!', whitespace: true }],
                                        })(<Input />)}

                                    </Form.Item>
                                    <Form.Item label="state">

                                        <select value={this.state.selectedState} onChange={this.handleStateChange}>
                                            {optionItems}
                                        </select>
                                    </Form.Item>
                                    <Form.Item label="zipcode">
                                        {getFieldDecorator('zipcode', {
                                            initialValue: this.state.zipcode,
                                            rules: [{ required: true, message: 'Please input your zipcode!', whitespace: true }],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item {...tailFormItemLayout}>
                                        <Button key="submit" type="primary" htmlType="submit">      Submit         </Button>
                                    </Form.Item>
                                </Form>
                                { /*ModalBox.call(this)*/}
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }
}

export default Form.create({ name: 'profile' })(Profile)