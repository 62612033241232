import React, { Component } from 'react';
import './Account.css'
import AccountHeader from './AccountHeader';
import {
  
    get_data_token,
    GET_TRANSACTION_LIST,
    GET_RESERVE_LIST,
    COMMIT_CANCEL,

} from '../../config/network'
import { showErrorMessage, ModalBox,  } from '../MessageBox';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { Col, Button,  FormGroup,  Input,Table, } from 'reactstrap';
import {   Modal,Tabs, Divider, Tag} from 'antd';
const { TabPane } = Tabs;
const { Column, ColumnGroup } = Table;
const datatest = [
    {
      key: '1',
      firstName: 'John',
      lastName: 'Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
     },
    {
      key: '2',
      firstName: 'Jim',
      lastName: 'Green',
      age: 42,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      firstName: 'Joe',
      lastName: 'Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
  ];
function callback(key) {
    console.log(key);
 }   

class Transactions extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            startDate: this.getInitStartTimeStr(),
            endDate: this.formatDate(new Date()),
            load:false,
            reserverecords: [],
            loadReserve:false,
            reservenull:false,
            selectedprocate: '',
            Confirmvisible:false,
        };
        
        this.getTransactions = this.getTransactions.bind(this);
        this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
        this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
        this.checkDateInput = this.checkDateInput.bind(this);
        this.getReserveTransactions=this.getReserveTransactions.bind(this);
        this.handleCancelRerve=this.handleCancelRerve.bind(this);
        //this.checkboxHandler=this.checkboxHandler.bind(this);
    }

    componentDidMount() {
        this.getTransactions();
        this.getReserveTransactions();
    }
    //
    getReserveTransactions() 
    {
        this.setState({loadReserve:true})
        get_data_token(GET_RESERVE_LIST, {})
           .then(data => {
                data.data.map(record => record.key = record.datetime);
                this.setState({ reserverecords: data.data,loadReserve:false,reservenull:true });
                console.log("getReserveTransactions", data)
           })
           .catch(err => {
               this.setState({ reserverecords: [],loadReserve:false,reservenull:false });

           })
    }
    //
    handleCancelRerve(event) {
        if (this.state.reservenull)
        {
          this.setState({Confirmvisible:true });
        }
        else
        {
            
        }
    }
    //
    getTransactions() {

         this.setState({load:true})
         get_data_token(GET_TRANSACTION_LIST, {
            startime: new Date(this.state.startDate).getTime() / 1000,
            endtime: new Date(this.state.endDate).getTime() / 1000,
           })
            .then(data => {
                 data.data.map(record => record.key = record.datetime);
                 this.setState({ records: data.data,load:false });
                //let TransactionsApi = data.data.map(productcate => { return { value: productcate.product_cate_name, key: productcate.product_cate_id } })
                //this.setState({ productcates: productcateFromApi });
                //this.setState({ records: TransactionsApi,load:false });
                console.log("getTransactions", data)
            })
             .catch(
                 err => {
                 this.setState({ records: [],load:false });
                 //showErrorMessage.call(this, 'Get transactions error:' + err);
             })

    }
    getInitStartTimeStr() {

        let d = new Date(Date.now() - 1000 * 86400 * 30);
        return this.formatDate(d);
    }
    formatDate(date) {
        
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    checkDateInput(dateStr){
        if(dateStr.search(/^\d{4}-\d{2}-\d{2}$/)===-1) {
            showErrorMessage.call(this,'Please input right date format: yyyy-mm-dd')
            return false;
        }
  
        return true;
    }
    handleChangeStartTime(event) {
        if(!this.checkDateInput(event.target.value))
            return;
        this.setState({
            startDate: event.target.value
        });
    }
    handleChangeEndTime(event) {
        if(!this.checkDateInput(event.target.value))
            return;
        this.setState({
            endDate: event.target.value
        });
    }
    //
    handleOk = () => {

        this.setState({Confirmvisible:false,});
        get_data_token(COMMIT_CANCEL, {rentalId:this.state.selectedprocate})
            .then(data => {
                this.getReserveTransactions() ;
                console.log("getTransactions", data)
            })
            .catch(err => {
                
                this.setState({ records: [],load:false ,Confirmvisible:false,});
               // showErrorMessage.call(this, 'Get transactions error:' + err);
            })

      
      };
     // 
    handleCancel = () => {
          console.log('Clicked cancel button');
          this.setState({
            Confirmvisible:false,
        });
      };
    //
    renderResevelistn() {
        if (this.state.loadReserve) 
            return <h5>Loading...</h5>;
        if (this.state.reserverecords.length === 0) 
            return <p>You have no transaction records.</p>;
        return (
            <Table dataSource={datatest}>
            <Column title="RentalId" dataIndex="rental_id" key="rental_id" />
            <Column title="ProductName" dataIndex="product_name" key="product_name" />
            <Column title="PickupCode" dataIndex="pickup_code" key="pickup_code" />
            <Column title="Brand" dataIndex="brand" key="brand" />
            <Column title="ReserveTime" dataIndex="reserve_time" key="reserve_time" />
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  {/* <a href="javascript:;">Delete</a> */}
                </span>
              )}
            />
          </Table>
        )
    }
    checkboxHandler(index,event) {
        this.setState({ selectedprocate:index});      
    }
    checkAll = () => {
        this.setState(prevState => {
          const json = prevState.json.map(obj => ({
            ...obj,
            items: obj.items.map(item => ({
              ...item,
              value: true
            }))
          }));
    
          return { json };
        });
    };
    //
    renderResevelist() {
        if (this.state.loadReserve) 
            return <h5>Loading...</h5>;
        if (this.state.reserverecords.length === 0) 
            return <p>You have no reserve records.</p>;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>RentalId</th>
                        <th>ProductName</th>
                        <th>PickupCode</th>
                        <th>Brand</th>
                        <th>ReserveTime</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.reserverecords.map((record,index) =>
                            <tr>
                            {/* <td key={record.id}> 
                                <p>{record.label}</p>
                                    <input 
                                        type="checkbox" 
                                        checked={record.value}
                                        onChange={this.checkboxHandler} 
                                    />
                            </td> */}
                            
                            {/* <select value={selectedprocate} style={{ width: 200 }} onChange={this.checkboxHandler}>
                            <td><option key="" value="">record.id.tostring()</option></td> */}
                            
                            {/* <td> <input type="checkbox" ></input></td>  */}
                            {/* <td><input type="option"  key={index} value="">{record.rentalid} onChange={this.checkboxHandler} ></input></td>  */}
                            <td> <input type="checkbox"
                                     key={record.rental_id} 
                                     name="reservelist"
                                     datacount={record.count}                                     
                                     onChange={this.checkboxHandler.bind(this,record.rental_id)} 
                                     >  
                                 </input>
                            </td>
                            <td>{record.rental_id}</td>
                            <td>{record.product_name}</td>
                            <td>{record.pickup_code}</td>
                            <td>{record.brand}</td>
                            <td>{new Date(parseInt(record.reserve_time)*1000).toLocaleString()}</td>
                            
                            {/* <p align='left'>
                            <Button color="primary"
                                    onClick={this.handleCancelRerve}><i class="fa fa-book"></i> Cancel reserve</Button>
                            </p>  */}
                            
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        )
    }
    //
    renderRecords() {
        if (this.state.load) 
            return <h5>Loading...</h5>;
        if (this.state.records.length === 0) 
            return <p>You have no transaction records.</p>;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>RentalId</th>
                        <th>ProductName</th>
                        <th>brand</th>
                        <th>CabinetId</th>
                        <th>Boxid</th>
                        <th>RentalTime</th>
                        <th>ReturnTime</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.records.map(record =>
                            <tr>
                            <td>{record.rental_id}</td>
                            <td>{record.product_name}</td>
                            <td>{record.brand}</td>
                            <td>{record.cabinet_id}</td>
                            <td>{record.box_id}</td>
                            <td>{new Date(parseInt(record.rental_time)*1000).toLocaleString()}</td>
                            <td>{record.return_time===null?"":new Date(parseInt(record.return_time)*1000).toLocaleString()}</td>
                          </tr>
                        )
                    }
                </tbody>
            </Table>
        )
    }
    render() {
        return (
            <div >
                <div className="main">
                    <AccountHeader page={"Transactions"} title="Transactions" />
                    <div className="main-body">

                        {/* <Tabs defaultActiveKey="1" onChange={callback} > */}
                          <Tabs defaultActiveKey="1" onChange={callback} > 
                              <TabPane tab="History" key="1">
                                 <p></p> 
                                 <FormGroup row>
                                 <Col sm={4}>
                                  {/* <DatePicker selected={this.state.startDate} onChange={this.handleChangeStartTime}/> */}
                                    <Input type='date' value={this.state.startDate} onChange={this.handleChangeStartTime} />
                                    </Col>
                                    <Col sm={4}>
                                    {/* <DatePicker selected={this.state.endDate} onChange={this.handleChangeEndTime}/> */}
                                       <Input type='date' value={this.state.endDate} onChange={this.handleChangeEndTime} />
                                  </Col>
                                  <Col sm={4}>
                                  <Button color="primary"
                                    onClick={this.getTransactions}><i class="fa fa-search"></i></Button>
                                  </Col>
                                  </FormGroup>                               
                                  {this.renderRecords()}                           
                              </TabPane>
                              <TabPane tab="Reserve List" key="2">
                                  <p></p>
                                  <FormGroup row>
                                  <Col sm={12}>
                                  <p align='left'>
                                  <Button color="primary"
                                    onClick={this.handleCancelRerve}><i class="fa fa-book"></i> Cancel reserve</Button>
                                  </p>
                                  </Col>
                                  </FormGroup> 
                                  {this.renderResevelist()}  
                                  <Modal
                                     title="Do you confirm cancellation of reservation?"
                                     visible={this.state.Confirmvisible} 
                                     onOk={this.handleOk.bind(this)}
                                     onCancel={this.handleCancel.bind(this)}
                                     >
                                  </Modal>
                              </TabPane>
                         </Tabs>
                         {/* ,
                         mountNode, */}
                    </div>
                </div>
                {ModalBox.call(this)}

            </div>
        );
    }
}

export default Transactions;