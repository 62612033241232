import React, { Component } from 'react';
import './Account.css'
//import DashboardCard from './DashboardCard'
import {
    Card,
    Descriptions,
    Row,
    Col,
    Button,
    Modal,
    Form,
    Input,

} from 'antd'
import { Link, } from 'react-router-dom';
import AccountHeader from './AccountHeader';
import {  GET_BIND_APARTMENT,GET_POSSESS_LIST,GET_USER_STAT_URL, GET_DEPT_LIST, get_data_token, BIND_APARTMENT,CANCELBIND_APARTMENT } from '../../config/network'
import { showErrorMessage, ModalBox } from '../MessageBox'
import { FormattedDate, FormattedMessage, FormattedTime, FormattedNumber } from 'react-intl';
import {  Table , } from 'reactstrap';

class Account extends Component {
      constructor(props) {
        super(props);
        this.state = {
            memberinfo: {},
            memberprofile: {},
            statusdetail: {},
            isProfilecompleted: '',
            isEmailVerified: '',
            hasBindAddress: '',
            transactions: [],
            productsinprocession: [],
            deptzipcode: '',
            bindzipcodevisible: false,
            selectDeptvisible: false,
            ModalText: 'Please input your zipcode first',
            deptlist: [],
            selecteddept: '',
            records: [],
            apartments: [],
            load:false,
            loadapartment:false,
            loadzipcode:false,
            loaddepartment:false,
            //
            cancelbinddept: '',
            cancelbindzipcodevisible: false,
            loadcancel:false,
        };
        this.showModal = this.showModal.bind(this);
        this.cancelshowModal = this.cancelshowModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDeptSubmit = this.handleDeptSubmit.bind(this);
        this.handlezipcodeSubmit = this.handlezipcodeSubmit.bind(this);
        this.handlezipcodeChange =   this.handlezipcodeChange.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.getApartment=this.getApartment.bind(this);
        //
        this.handlecanceldeptChange = this.handlecanceldeptChange.bind(this);
        this.handlecancelbindSubmit=this.handlecancelbindSubmit.bind(this);

    }
    //
    cancelshowModal = () => {
        this.setState({
            //ModalText: 'Please input your departmentID',
            ModalText: '',
            cancelbinddept: '',
            cancelbindzipcodevisible: true,
            loadcancel:false,
        });
    };
    //
    showModal = () => {
        this.setState({
            //ModalText: 'Please input your zipcode first',
            ModalText: '',
            deptzipcode: '',
            bindzipcodevisible: true,
            loadzipcode:false,
        });
    };
    //cancel输入事件
    handlecanceldeptChange(event) {
            this.setState({ cancelbinddept: event.target.value,loadcancel:false, });
    }
    //
    rendercancelbind() {
            if (this.state.loadcancel) 
               return <h5>Loading...</h5>;
    
            return (
                <Modal
                title="Input your bound Organization ID"
                visible={this.state.cancelbindzipcodevisible}
				onOk={this.handlecancelbindSubmit}
                onCancel={this.handleCancel}
                >
                <Form >
                    <Form.Item label="organizationid">
                        <Input type="text" value={this.state.cancelbinddept}  onChange={this.handlecanceldeptChange}/>
                    </Form.Item>
                </Form>
                <p>{this.state.ModalText}</p>
               </Modal>
          )
        }
        //cancelbind界面 submit事件
        handlecancelbindSubmit(event) {
            let deptid=this.state.cancelbinddept;
            if(deptid===undefined)
            {
                return;
            }
            if(deptid===null)
            {
                return;
            }
            if(deptid==="")
            {
                    this.setState({
                        ModalText: 'Error organizationid,please input again',
                        loadcancel:false,
                             }) 
             return;
            }
            get_data_token(CANCELBIND_APARTMENT, { organizationId: deptid })
            .then(
                data => {
    
                     this.getApartment(); 
                     this.setState({ cancelbindzipcodevisible: false,   loadcancel:false,});
                     return;
    
               })
              .catch(
                err => showErrorMessage.call(this, 'Cancel Bind organization data error:' + err)
            )
            //
            this.setState({
                ModalText: 'No such organization!',
                loadcancel:false,
             }) 
       
         }
    //zipcode界面 OK事件
    handleOk = () => {
        this.setState({
            ModalText: 'The modal will be closed after two seconds',
            loadzipcode:false,
            //loaddepartment:true,
        });
        setTimeout(() => {
            this.setState({
                deptzipcode: '',
                bindzipcodevisible: false,
                bindDeptvisible: false,
                selectDeptvisible: false,  
                cancelbindzipcodevisible: false,          
                loaddepartment:false,
                loadzipcode:false,
                loadcancel:false,

            });
        }, 2000);
    };
    //zipcode界面 Cancel事件
    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            deptzipcode: '',
            bindzipcodevisible: false,
            selectDeptvisible: false,
            cancelbindzipcodevisible: false, 
            loadzipcode:false,
            loaddepartment:false,
            loadcancel:false,
        });
    };
    //
    handleChange(event) {
        this.setState({ selecteddept: event.target.value,loaddepartment:false, });
    }
    //
    handlezipcodeChange(event) {
        this.setState({ deptzipcode: event.target.value,loadzipcode:false, });
    }
    //
    renderZipcode() {

        if (this.state.loadzipcode) 
           return <h5>Loading...</h5>;

        return (
            <Modal
            title="Select your zipcode"
            visible={this.state.bindzipcodevisible}
            //onOk={this.handleOk}
			onOk={this.handlezipcodeSubmit}
            onCancel={this.handleCancel}
            >
            <Form >
                <Form.Item label="zipcode">
                    <Input type="text" value={this.state.deptzipcode}  onChange={this.handlezipcodeChange}/>
                </Form.Item>
            </Form>
            <p>{this.state.ModalText}</p>
           </Modal>
      )
    }
    //
    renderDepartment() {
        if (this.state.loaddepartment) 
           return <h5>Loading...</h5>;
         const deptlist = this.state.deptlist;
         let Deptoptions = deptlist.map((item) =>
                //    <option value={item.apartmentId}>{item.apartmentName}</option>
                <option value={item.value}>{item.key}</option>
         );

        //let selectDeptvisible=this.state.selectDeptvisible;
        return (
            <Modal
            title="Select Organization"
            visible={this.state.selectDeptvisible}
            //visible={selectDeptvisible}
            //onOk={this.handleOk}
			onOk={this.handleDeptSubmit}
            onCancel={this.handleCancel}
        >
            <Form >
                <Form.Item label="Organization">

                    <select value={this.state.selecteddept} onChange={this.handleChange}>
                        {Deptoptions} 
                    </select>
                </Form.Item>

            </Form>

            <p>{this.state.ModalText}</p>
           </Modal>
      )
    }
    //
    renderApartments() {
        let approvestatus;
        if (this.state.loadapartment) 
            return <h5>Loading...</h5>;
        if (this.state.apartments.length === 0) 
            return <p>You have not linked your account with any organization yet. please bind your account with an organization.</p>;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Organization ID</th>
                        <th>Organization Name</th>
						<th>Approve Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.apartments.map(record =>
                            <tr>                 
                            <td>{record.organization_id}</td>
                            <td>{record.organization_name}</td>
                            <td>{
                                approvestatus=(record.approve_status==='1')?"approved":"to be approved"
                                }
                            </td>
                          </tr>
                        )
                    }
                </tbody>
            </Table>
        )
    }
    //
    renderRecords() {
        if (this.state.load) 
            return <h5>Loading...</h5>;
        if (this.state.records.length === 0) 
            return <p>You have no transaction records.</p>;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>ProductName</th>
                        <th>brand</th>
                        <th>RentalTime</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.records.map(record =>
                            <tr>
                            <td>{record.product_name}</td>
                            <td>{record.brand}</td>
                            <td>{new Date(parseInt(record.rental_time)*1000).toLocaleString()}</td>
                          </tr>
                        )
                    }
                </tbody>
            </Table>
        )
    }
    //zipcode界面 submit事件
    handlezipcodeSubmit(event) {
       let deptzipcode=this.state.deptzipcode;
       if(deptzipcode===undefined)
       {
           return;
       }
       if(deptzipcode===null)
       {
           return;
       }
       if(deptzipcode==="")
       {
               this.setState({
                   ModalText: 'Error zipcode,please input zipcode again',
                         loadzipcode:false,
                        }) 
        return;
       }
       get_data_token(GET_DEPT_LIST, { zipcode: deptzipcode })
            .then(

                 data=> {
                      if (data.apartmentList.length===0)
                      {
                        this.setState({
                            ModalText: 'Error zipcode,please input zipcode again',
                                  loadzipcode:false,
                                 }) 
                        return;
                      }
                      else
                      //
                      {
                       let departmentlistapi = data.apartmentList.map((record,index) => {   
                                 

                       if(index===0)
                       {
                        this.setState({selecteddept:record.organizationId,});
                       }  
                       return { value:record.organizationId,key:record.organizationName} 
                       });
                       this.setState({
                                ModalText: '',
                                deptlist: departmentlistapi,
                                bindzipcodevisible: false,
                                loadzipcode:false,
                                selectDeptvisible: true,
                                loaddepartment:false,
                       });
                    }
                    //

              })
            .catch(
                err => showErrorMessage.call(this, 'Get department data error:' + err)
            )
  
    }
    //绑定提交
    handleDeptSubmit(event) {
        get_data_token(BIND_APARTMENT, { organizationId: this.state.selecteddept})
        .then(
            data => {

                 this.getApartment(); 
                 this.setState({ selectDeptvisible: false,  loaddepartment:false,});
                 return;

        })
        .catch(
            err => showErrorMessage.call(this, 'Bind organization data error:' + err)
        )
        //
        this.setState({
            ModalText: 'You have bound to this organization already!',
            loaddepartment:false,
         }) 

    }

    // handleDeptChange(event) {

    //     this.setState({ selecteddept: event.target.value,loaddepartment:false, });


    // }
    componentDidMount() {
        if (localStorage.getItem('accessToken') == null) {
            this.setState({ reload: true });
            return;
        }
        get_data_token(GET_USER_STAT_URL, {})
            .then(data => {
                this.setState({
                    memberinfo: data.member,
                    memberprofile: data.profile,
                    isProfileCompleted: data.member.statusDetail.isProfileCompleted,
                    hasBindAddress: data.member.statusDetail.hasBindAddress,
                })
                //departmentid
                this.getApartment();
                //
                this.getTransactions();
            })
            .catch(err => showErrorMessage.call(this, 'Get user statistics error:' + err))
    }
   //
   getApartment() 
   {
    this.setState({loadapartment:true})   
    get_data_token(GET_BIND_APARTMENT, {})
    .then(data => {
        data.map(record => record.key);
        this.setState({ apartments: data, loadapartment:false});
        console.log("getApartment", data)
    })
    .catch(err => {
        this.setState({apartments: [], loadapartment:false});
        showErrorMessage.call(this, 'Get apartment error:' + err);
    })
   }
   //
   getTransactions() {
    this.setState({load:true})
    get_data_token(GET_POSSESS_LIST, {})
        .then(data => {
             data.data.map(record => record.key = record.datetime);
             this.setState({ records: data.data,load:false });
             console.log("getTransactions", data)
        })
        .catch(err => {
            this.setState({ records: [],load:false });
            showErrorMessage.call(this, 'Get transactions error:' + err);
        })
    }
//

//
    render() {
        //

        //
        const memberinfo = this.state.memberinfo;
        const memberprofile = this.state.memberprofile;
        let completeinfo;
        if(Object.keys(memberinfo).length===0)
        {completeinfo="Not complete profile";}
        else
        {completeinfo=(memberinfo.statusDetail.isProfileCompleted===1)?"Complete profile":"Not complete profile";}
        return (
            <div > 
                <div className="main">
                    <AccountHeader page={"Account"}
                        title={<FormattedMessage id="page.sidnav.title.Dashboard" defaultMessage="Dashboard" />}
                    />

                    <div className="main-body">
                        <Row gutter={16}>

                            <Col span={4}>

                            </Col>

                            <Col span={16}>
                                <Card title="User Info" size="default" bordered={false}>
                                    <Descriptions
                                        title=""
                                        bordered
                                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                                    >
                                        <Descriptions.Item label="Nick Name" >{memberprofile.nickName} </Descriptions.Item>
                                        <Descriptions.Item label="Phone" >{memberinfo.phone}</Descriptions.Item>
                                        <Descriptions.Item label="Status" span={3}>
                                              {completeinfo}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="FirstName">{memberprofile.firstName}</Descriptions.Item>
                                        <Descriptions.Item label="LastName">{memberprofile.lastName}</Descriptions.Item>
                                        <Descriptions.Item label="Address" span={4}>{memberprofile.addressline1} <p></p> {memberprofile.city + "  "}  {memberprofile.state + " "}  {memberprofile.zipcode}</Descriptions.Item>

                                    </Descriptions>
                                    <p />
                                    <p />
                                    <p align='right'><Link to="/account/profile" Class="pull-right title-link"><Button type='primary' size='large'>Update Profile</Button></Link></p>
                                </Card>
                                <Card title="Bind Organization" size="default" bordered={false}>
                                    {this.renderApartments()}
                                    <p align='right'>
                                      <Button style={{marginRight: 8}} type='primary' size='large' onClick={this.cancelshowModal} >Cancel Binded Organization</Button>
                                      <Button style={{marginRight: 8}} type='primary' size='large' onClick={this.showModal}>Bind Organization</Button>
                                    </p>
                                    {this.renderZipcode()}
                                    {this.renderDepartment()}  
                                    {this.rendercancelbind()}                        
                                </Card>
                                <Card title="Your Current Possessions" size="default" bordered={false}>

                                           {this.renderRecords()} 

                                     
                                </Card>

                                <Card title="Transaction History" size="default" bordered={false}>
                                       <p align='right'><Link to="/account/transactions" Class="pull-right title-link"><Button type='primary' size='large'>Transactions</Button></Link></p>
                                </Card>
                            </Col>
                            <Col span={4}>

                            </Col>
                        </Row>
                    </div>


                </div>

            </div>
        );
    }
}

export default Account;