import React, { Component } from "react";
import Header from "../Header";
//import Footer from '../Footer';
import "./product.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/antd.css";
//import AccountHeader from './AccountHeader';
import { Card, Row, Col } from "antd";
import AccountHeader from '../Account/AccountHeader';
import {
  IMAGE_URL,
  GET_BIND_APARTMENT,
  GET_PRODUCT_LIST,
  GET_PROD_CATE_LIST,
  get_data_token
} from "../../config/network";
import { Link } from "react-router-dom";
import { showErrorMessage, showSuccessMessage, ModalBox } from "../MessageBox";
import { FormGroup } from "reactstrap";
//import { ninvoke } from 'q';

//const { Option } = Select;

class ProductSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      productcates: [],
      departments: [],
      selectedprocate: "",
      selectdepartment: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  //产品类别选择事件
  handleChange = event => {
    this.setState({ selectedprocate: event.target.value });
  };
  //绑定厂商选择事件
  handleChangeSelectDepartment = event => {
    this.setState({ selectdepartment: event.target.value });
    //获取产品列表
    get_data_token(GET_PRODUCT_LIST, { organizationId: event.target.value })
      .then(data => {
        this.setState({ products: data.productList });
      })
      .catch(err => {
        this.setState({ products: [] });
        showErrorMessage.call(this, "Get product info," + err);
      });
    //获取产品类别
    get_data_token(GET_PROD_CATE_LIST, { organizationId: event.target.value })
      .then(data => {
        let productcateFromApi = data.productcategoryList.map(productcate => {
          return {
            value: productcate.product_cate_name,
            key: productcate.product_cate_id
          };
        });
        this.setState({ productcates: productcateFromApi });
        console.log(this.state.productcates);
      })
      .catch(err => {
        this.setState({ productcates: [] });
        showErrorMessage.call(this, "Get product category info," + err);
      });
  };
  componentWillMount() {
    //获取绑定列表
    this.getDepartment();
  }
  //获取绑定Department列表
  getDepartment() {
    get_data_token(GET_BIND_APARTMENT, {})
      .then(data => {
        let departmentFromApi = data.map((record, index) => {
          if (index === 0) {
            this.setState({ selectdepartment: record.organization_id });
            //
            //获取产品列表
            get_data_token(GET_PRODUCT_LIST, {
              organizationId: record.organization_id
            })
              .then(data => {
                this.setState({ products: data.productList });
              })
              .catch(err => {
                showErrorMessage.call(this, "Get product info," + err);
              });
            //获取产品类别
            get_data_token(GET_PROD_CATE_LIST, {
              organizationId: record.organization_id
            })
              .then(data => {
                let productcateFromApi = data.productcategoryList.map(
                  productcate => {
                    return {
                      value: productcate.product_cate_name,
                      key: productcate.product_cate_id
                    };
                  }
                );
                this.setState({ productcates: productcateFromApi });
                console.log(this.state.productcates);
              })
              .catch(err => {
                showErrorMessage.call(this, "Get product category info," + err);
              });
            //
          }
          return {
            value: record.organization_name,
            key: record.organization_id
          };
        });
        this.setState({ departments: departmentFromApi });
        console.log("getDepartment", departmentFromApi);
      })
      .catch(err => {
        this.setState({ departments: [] });
        showErrorMessage.call(this, "Get department error:" + err);
      });
  }
  //
  render() {
    const departments = this.state.departments;
    const productcates = this.state.productcates;
    let selectedprocate = this.state.selectedprocate;
    let selectdepartment = this.state.selectdepartment;
    var text = "red";
    var style = {
      color: text
    };
    // console.log(selectedprocate);
    const productcatelist = productcates.map(productcatitem => (
      <option key={productcatitem.key} value={productcatitem.key}>
        {productcatitem.value}
      </option>
    ));
    //console.log(productcatelist);
    const departmentslist = departments.map(departmentitem => (
      <option key={departmentitem.key} value={departmentitem.key}>
        {departmentitem.value}
      </option>
    ));
    //
    const products = this.state.products;
    const productList = products.length
      ? products
          .filter(productcategory =>
            selectedprocate !== ""
              ? productcategory.category_id === selectedprocate
              : productcategory.category_id !== selectedprocate
          )
          .map(productitem => (
            <Col span={6}>
              <Card title={productitem.product_name} bordered={true}>
                {/* {productitem.available===0?<p align='center' ><p>No rent</p></p>:          
                      <p align='center' style={style}>
                       {  productitem.available===0? <p>Rented</p>:<p>No deliver</p>}
                      </p>
                } */}
                <div class="imageblock">
                  <Link to={`/productdetails/${productitem.product_id}`}>
                    <div class="custom-image">
                      {/* <img alt={productitem.product_name} src={'http://admin.zipcodexpress.unibox.com.cn/' + productitem.product_thumbnail} /> */}
                      <img
                        alt={productitem.product_name}
                        src={IMAGE_URL + productitem.product_thumbnail}
                      />
                    </div>
                    <div class="custom-card">
                      <p>{productitem.product_desc}</p>
                    </div>
                  </Link>
                </div>
              </Card>
            </Col>
          ))
      : "did not get any products";

    return (
      <div>
        <div className="main">
          <AccountHeader page={"Product"} title="Product" />

          <div className="main-body">
            <div>
              <p>
                <h4>Please select your bind organization</h4>
                <select
                  value={selectdepartment}
                  style={{ width: 200 }}
                  onChange={this.handleChangeSelectDepartment.bind(this)}
                >
                  {/* <option key="" value=""></option> */}
                  {departmentslist}
                </select>
              </p>
              <p>
                <h4>Please select product category</h4>
                <select
                  value={selectedprocate}
                  style={{ width: 200 }}
                  onChange={this.handleChange}
                >
                  <option key="" value="">
                    Select Product Category
                  </option>
                  {productcatelist}
                </select>
              </p>
            </div>
            <div
              style={{
                background: "#ECECEC",
                padding: "30px",
                align: "center"
              }}
            >
              <Card
                title={this.props.cartTitle}
                bordered={true}
                style={{
                  width: this.props.width
                }}
              >
                <Row gutter={16}>{productList}</Row>
              </Card>
            </div>
          
          </div>
        </div>
      </div>
    );
  }
}

export default ProductSelect;
